import axios from 'axios';
// import https from 'https';

// const agent = new https.Agent({
//     rejectUnauthorized: false
// });

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    // httpsAgent: agent
});

export default axiosInstance;