import { FC, memo } from "react";

import { ArrowForwardIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Badge, Box, Button, Center, Flex, Image, Link, ListItem, Stack, Text, UnorderedList } from "@chakra-ui/react";
import { AiFillLike } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { UdemyCourseRankingType } from "../../types/udemyCourseRankingType";
import { SocialLinksByCourse } from "../molecules/SocialLinksByCourse";
import { badgeColorCodeIsActive, badgeColorCodeNonActive } from "../shared/colorCode";



type AnalysisResultBoxType = {
    rankingData: UdemyCourseRankingType;
    tagName?: string;
    id?: string;
};


export const AnalysisResultBox: FC<AnalysisResultBoxType> = memo((props) => {

    const { rankingData, tagName = "", id } = props;
    const history = useHistory();


    return (
        <Center py={{ base: "1", md: "3" }} id={id}>
            <Box bg="white" w={{ base: "95%", md: "70%" }} borderRadius="lg" shadow="md">
                <Stack spacing={{ base: "1", md: "3" }} py={4} px={{ base: "5", md: "10" }} >
                    <Stack align="center" spacing={8} direction='row'>
                        <Box
                            width={{ base: "40%", sm: "35%", md: "30%" }}
                            display="inline-block"
                            position="relative"
                            overflow="hidden"
                        >
                            <a href={rankingData.udemyUrl} style={{ textDecoration: 'none', display: 'block' }}>
                                <Image
                                    width="100%"
                                    height="100%"
                                    objectFit="cover"
                                    src={rankingData?.udemyCourseImage}
                                    alt={rankingData?.udemyTitle}
                                    _hover={{ opacity: 0.7, cursor: 'pointer' }}
                                    cursor="pointer"
                                />
                            </a>
                        </Box>

                        <Stack spacing={{ base: "1", md: "5" }}>
                            <Flex align='center' gap='3'>
                                <Text fontStyle='bold' fontSize={{ base: "20", md: "25" }}>{rankingData.rankingNum}.</Text>
                                <Text fontSize={{ base: "15", md: "20" }}>{rankingData.udemyLgtm} LGTM</Text>
                            </Flex>
                            <Link color="teal.500" href={rankingData.udemyUrl}>
                                <Text fontSize={{ base: "15", md: "lg" }}>
                                    {rankingData.udemyTitle}
                                </Text>
                            </Link>
                            <Text fontSize={{ base: "10", md: "sm" }} color="gray.500">
                            </Text>
                        </Stack>
                    </Stack>

                    <Stack direction='row' spacing={{ base: "0", md: "2" }} align='center'>
                        <Text fontSize={{ base: "12", md: "sm" }}>
                            本講座をシェア &nbsp;</Text>
                        <SocialLinksByCourse udemyTitle={rankingData?.udemyTitle} udemyUrl={rankingData.udemyUrl} />
                    </Stack>

                    <Text fontSize={{ base: "12", md: "sm" }}>
                        作成者 &nbsp;
                        {rankingData?.teachers?.map((teacher, index) => {
                            return (
                                <Link key={index} target="_blank" color="teal.500" href={teacher.udemyTeacherUrl} isExternal style={{ display: "inline" }}>
                                    {teacher.name}{teacher.jobTitle}&nbsp;<ExternalLinkIcon mx='2px' />
                                </Link>
                            )
                        })}
                    </Text>

                    <Stack direction='row' spacing={{ base: "0", md: "2" }} align='center'>
                        <Text fontSize={{ base: "12", md: "sm" }} p={1} w={{ base: "12", md: "14" }}>
                            Tag &nbsp;
                        </Text>
                        <Box >
                            {rankingData?.tags?.map((badge, index) => {
                                return (
                                    <Badge key={index} variant='solid' mx={1} my={1} colorScheme='green'
                                        bg={badge.tagName === tagName ? badgeColorCodeIsActive : badgeColorCodeNonActive}
                                        fontSize={{ base: "10", md: "xs" }} p={1}
                                        onClick={() => history.push({ pathname: '/tag-ranking', state: { tagName: badge.tagName, unique: Date.now(), key: Date.now().toString() } })}
                                        sx={{
                                            transition: "opacity .3s ease",
                                            '&:hover': {
                                                opacity: 0.7,
                                                cursor: 'pointer',
                                            }
                                        }}>
                                        {badge.tagName}
                                    </Badge>
                                )
                            })}
                        </Box>
                    </Stack>

                    <Stack spacing={0} pt={1} fontSize={{ base: "6", md: "sm" }}>
                        <Text fontSize={{ base: "12", md: "sm" }} py={0}>
                            引用されているQiita記事：
                        </Text>
                        <UnorderedList px={5} spacing={0} py={0} fontSize={{ base: "sm", md: "md" }} >
                            {rankingData?.quotedQiitaArticles?.map((article, index) => {
                                return (
                                    <ListItem key={index}>
                                        <Link color="teal.500" href={article.qiitaUrl} target="_blank">
                                            {article.title} &nbsp;
                                            <Box style={{ 'display': "inline" }} fontSize={{ base: "sm", md: "md" }}>
                                                <AiFillLike style={{ 'display': "inline" }} />
                                                {article.lgtmCount}
                                            </Box>
                                        </Link>
                                    </ListItem>
                                )
                            })}
                        </UnorderedList>
                    </Stack>
                    <Center>
                        <Box as="a" href={rankingData.udemyUrl} display="inline-block" textDecoration="none" w="60%" h={{ base: "6", md: "10" }}>
                            <Button borderRadius="lg" w="100%" h="100%"
                                fontSize={{ base: "sm", md: "md" }} rightIcon={<ArrowForwardIcon />} colorScheme='teal' variant='outline'>
                                Udemy
                            </Button>
                        </Box>
                    </Center>
                </Stack>
            </Box>
        </Center >
    )
});