import { Box, Center, Flex, Spacer } from "@chakra-ui/react";
import { FC, memo } from "react";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { SiFacebook } from "react-icons/si";

export const SnsIconLayout: FC = memo(() => {

    const socialText = "ITスキルに特化した全Qiita記事を、毎日自動分析。オススメのUdemy講座をランキングで紹介するサイト。\n #Udemy #Qiita @Zero__Cheeseより";
    const url_str = "https://udemytechranking.com/";

    // TwitterとFacebookの投稿リンクを作成します
    const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(socialText)}&url=${encodeURIComponent(url_str)}`;
    // const facebookLink = `https://www.facebook.com/dialog/share?app_id=YOUR_APP_ID&display=popup&href=${encodeURIComponent(url_str)}&quote=${encodeURIComponent(socialText)}`;
    const facebookLink = `http://www.facebook.com/share.php?u=${url_str}`;


    return (
        <>
            <Box
                fontSize={{ base: "lg", md: "md" }}
                style={{
                    position: "fixed",
                    top: "50%",
                    left: 20,
                    transform: "translateY(-50%)",
                }}
                display={{ base: "none", md: "block" }}
            >
                <Center as="a" target="_blank" href={twitterLink}>
                    <FaTwitter
                        size={40}
                    // color="#1DA1F2"
                    />
                </Center>
                <br />
                <Center as="a" target="_blank" href={facebookLink}>
                    <FaFacebookF
                        size={40}
                    // color="#3B5998"
                    />
                </Center>
            </Box>

            <Box
                fontSize={{ base: "lg", md: "md" }}
                w="100%"
                style={{
                    position: "fixed",
                    top: "90%",
                    // transform: "translateY(-50%)",
                }}
                display={{ base: "block", md: "none" }}
            >
                <Flex align="center" justify="center" px={2}>
                    <Center as="a" target="_blank" href={twitterLink}>
                        <AiFillTwitterCircle
                            size={30}
                            color="#1DA1F2" />
                    </Center>
                    <Spacer />
                    <Center as="a" target="_blank" href={facebookLink}>
                        <SiFacebook
                            size={30}
                            color="#3B5998"
                        />

                    </Center>

                </Flex>
            </Box>
        </>
    )
});