import { Box, Center, Heading, Image, Link, ListItem, OrderedList, Text, UnorderedList } from "@chakra-ui/react";
import { FC, memo, } from "react";

import badgeFilterArea from "../../images/mod_badge_filter_area.jpg";
import glassIconArea from "../../images/mod_glass_icon_area.png";
import termFilterArea from "../../images/mod_term_filter_area.png";
import udemyRankingHowTo from "../../images/mod_udemy_ranking_how_to.png";


export const About: FC = memo(() => {

    return (
        <>
            <Center p={0} m={4} w='100%'>
                <Box p={0} m={4} w={{ base: "95%", sm: "90%", md: "80%" }}>
                    <Heading as="h1" size="lg">Udemy講座のランキング方法</Heading>
                    <Box height="1px" bg="transparent" borderBottom="2px" borderStyle="dashed" borderColor="yellow.500" mt={1} mb={4} />
                    <Text mt={4} fontSize="lg">
                        本サイトでは、全Qiita記事（2023年 2/13〜）を分析し、Udemy講座をランキングしています。
                    </Text>

                    <Box my="5" p="5" borderWidth={2} borderRadius="md" borderColor="teal.500" w={{ base: "95%", sm: "90%", md: "80%" }} shadow="lg">
                        <Text fontSize="lg" fontWeight="bold">以下の手順を用いて、ランキングしています。</Text>
                        <Box height="1px" bg="transparent" borderBottom="2px" borderStyle="dashed" borderColor="teal.500" my={1} />

                        <OrderedList mt={4}>
                            <ListItem>「Qiita記事」から、「Udemy講座」を抽出</ListItem>
                            <ListItem>抽出した「Udemy講座」ごとに、言及している「全Qiita記事」をリスト化</ListItem>
                            <ListItem>リスト内の「Qiita記事」LGTM（いいね！）の合計値を、「Udemy講座」のLGTMとし、ランキング</ListItem>
                        </OrderedList>

                        <Image src={udemyRankingHowTo} alt="Udemy講座のランキング方法" mt={4} boxSize={{ base: "95%", sm: "89%", md: "82%", lg: "75%" }} />
                    </Box>


                    <Heading as="h2" size="lg" mt={10}>Webサイトの使い方</Heading>
                    <Box height="1px" bg="transparent" borderBottom="2px" borderStyle="dashed" borderColor="yellow.500" my={1} />

                    <Text mt={4} fontSize="lg">
                        下記の条件で、フィルター可能です。
                    </Text>


                    <UnorderedList mt={4} styleType="disc" spacing={2}>
                        <ListItem>Qiita記事の投稿時期：直近1ヶ月以内 or 全期間（デフォルト）</ListItem>
                        <ListItem>Qiitaタグ</ListItem>
                    </UnorderedList>

                    <Text mt={4} fontSize="lg">具体的なフィルタリング方法は、以下の通りです：</Text>

                    <Box my="5" p="5" borderWidth={2} borderRadius="md" borderColor="teal.500" w={{ base: "95%", sm: "90%", md: "80%" }} shadow="lg">
                        <Heading as="h3" size="md" mt={0}>Qiita記事の投稿時期でフィルターしたい場合</Heading>
                        <Box height="1px" bg="transparent" borderBottom="2px" borderStyle="dashed" borderColor="teal.500" mt={2} mb={4} />
                        <Text mt={4} fontSize="lg">
                            直近一ヶ月以内に投稿された、Qiita記事に絞った分析による、ランキング表示が可能です。<br />
                            最新のトレンドの把握が、可能です。<br />
                            またデフォルトでは、全期間のQiita記事になっており、普遍的な必要なスキルという観点で、確認する事ができます。
                        </Text>
                        <Image src={termFilterArea} alt="投稿時期でのフィルター方法" mt={4} boxSize={{ base: "95%", sm: "89%", md: "82%", lg: "75%" }} />
                    </Box>

                    <Box my="5" p="5" borderWidth={2} borderRadius="md" borderColor="teal.500" w={{ base: "95%", sm: "90%", md: "80%" }} shadow="lg">
                        <Heading as="h3" size="md" mt={0}>Qiitaタグでフィルターしたい場合</Heading>
                        <Box height="1px" bg="transparent" borderBottom="2px" borderStyle="dashed" borderColor="teal.500" mt={2} mb={4} />
                        <Text mt={4} fontSize="lg">
                            タグをタップ（クリック）する事により、フィルター可能です。<br />
                            これにより、あなたが興味のある分野のみで、ランキングを見る事ができます。
                        </Text>

                        <Box as="div" mt={4}>
                            <Image src={badgeFilterArea} alt="Qiitaタグでのフィルター方法" mt={4} boxSize={{ base: "95%", sm: "89%", md: "82%", lg: "75%" }} />
                            <Text mt={8}>もしくは、サイト左上の虫メガネアイコンからも、直接入力が可能です。</Text>
                            <Image src={glassIconArea} alt="虫メガネアイコンによるフィルター方法" mt={4} boxSize={{ base: "95%", sm: "89%", md: "82%", lg: "75%" }} />
                        </Box>
                    </Box>



                    <Heading as="h2" size="lg" mt={10}>参考にさせて頂いた記事</Heading>
                    <Box height="1px" bg="transparent" borderBottom="2px" borderStyle="dashed" borderColor="yellow.500" my={1} />
                    <Box my="5" p="5" borderWidth={2} borderRadius="md" borderColor="teal.500" w={{ base: "95%", sm: "90%", md: "80%" }} shadow="lg">
                        <Text mt={0} fontSize="lg">
                            本サイト作成にあたり、Qiita記事の
                            <Link color="teal.500" textDecoration="underline"
                                href="https://qiita.com/organizations/admin-guild"
                                _hover={{ opacity: "60%" }}
                                target="_blank">@jabba(運営者ギルド)さん</Link>
                            が執筆された、
                            <Link href="https://qiita.com/jabba/items/edefda09121877b79760"
                                color="teal.500" textDecoration="underline"
                                _hover={{ opacity: "60%" }}
                                target="_blank">
                                「技術書ランキングサイトをQiita記事の集計から作ったら、約4000冊の技術本がいい感じに並んだ」
                            </Link>
                            を参考にさせて頂きました。
                        </Text>
                        <Text mt={2} fontSize="lg">
                            大変重宝させて頂きました。<br />この場をお借りしまして、お礼を申し上げます。
                        </Text>
                    </Box>
                </Box>
            </Center>
        </>
    );
});
