import { Button, HStack, Link } from "@chakra-ui/react";
import { FC, memo } from "react";
import { FaFacebook, FaTwitter } from "react-icons/fa";

type SocialLinksByCourseType = {
    udemyTitle: string;
    udemyUrl: string;
};

export const SocialLinksByCourse: FC<SocialLinksByCourseType> = memo((props) => {

    const { udemyTitle, udemyUrl } = props;

    const url = new URL(udemyUrl).searchParams.get('murl');

    const socialText = `${udemyTitle} \n Udemy TechRankingより \n #Udemy `;

    let url_str: string = "";
    if (url) {
        url_str = url.toString();
    } else {
        url_str = "";
    }

    // TwitterとFacebookの投稿リンクを作成します
    const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(socialText)}&url=${encodeURIComponent(url_str)}`;
    // const facebookLink = `https://www.facebook.com/dialog/share?app_id=1463376554403579&display=popup&href=${encodeURIComponent(url_str)}&quote=${encodeURIComponent(socialText)}`;
    const facebookLink = `http://www.facebook.com/share.php?u=${url_str}`;


    return (
        <HStack spacing="24px">
            <Link href={twitterLink} isExternal py={0} my={0}>
                <Button size="sm" colorScheme="twitter" leftIcon={<FaTwitter />} py={0} my={0}>
                    Twitter
                </Button>
            </Link>
            <Link href={facebookLink} isExternal py={0} my={0}>
                <Button size="sm" colorScheme="facebook" leftIcon={<FaFacebook />} py={0} my={0}>
                    Facebook
                </Button>
            </Link>
            {/*  */}
        </HStack>
    );
});
