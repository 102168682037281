import { FC } from "react";
import { Route, Switch } from "react-router-dom";
import { About } from "../components/pages/About";
import { Contact } from "../components/pages/Contact";
import { Home } from "../components/pages/Home";
import { NotFound } from "../components/pages/NotFound";
import { TagRanking } from "../components/pages/TagRanking";
import { HeaderLayout } from "../components/templates/HeaderLayout";

import { useLocation } from "react-router-dom";

interface LocationState {
    tagName?: string;
    unique?: Date;
}

export const Router: FC = () => {

    const location = useLocation<LocationState>();
    return (
        <Switch>
            <Route exact path="/">
                <HeaderLayout>
                    <Home />
                </HeaderLayout>
            </Route>
            <Route exact path="/tag-ranking">
                <HeaderLayout>
                    <TagRanking key={location.key} />
                </HeaderLayout>
            </Route>
            <Route exact path="/about">
                <HeaderLayout>
                    <About />
                </HeaderLayout>
            </Route>
            <Route exact path="/contact">
                <HeaderLayout>
                    <Contact />
                </HeaderLayout>
            </Route>

            <Route path="*">
                <HeaderLayout>
                    <NotFound />
                </HeaderLayout>
            </Route>
        </Switch>
    );
};