import {
    Box,
    Center,
    useBreakpointValue
} from "@chakra-ui/react";
import { FC, memo } from "react";
import { Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis } from 'recharts';
import { UdemyCourseRankingType } from "../../types/udemyCourseRankingType";

type Props = {
    udemyCourseRankingsData: UdemyCourseRankingType[];
    barGraphClickListner: (index: number) => void
};

const CustomizedBar: FC<any> = (props) => {
    const {
        x, y, width, height, fill, payload
    } = props;
    const gap = 0;  // gap in pixels

    const barWidth = Math.max(30, width);  // bar width
    const adjustedWidth = Math.min(barWidth, 50);  // set max width to 20
    const adjustedHeight = Math.max(0, height - gap - 0.2);  // make sure height is not less than 0

    return (
        <g>
            <image xlinkHref={payload.name} x={x} y={y + adjustedHeight + gap} height={40 - gap} width={adjustedWidth} />
            <rect x={x} y={y + gap} width={adjustedWidth} height={adjustedHeight} fill={fill} />
        </g>
    );
};


const CustomizedAxisTick: FC<any> = () => {
    return (
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)"></text>
    );
};

const CustomTooltip: FC<any> = (props) => {
    if (props.active && props.payload && props.payload.length) {
        return (<Box p={3} bg="white" boxShadow="md" borderRadius="md">{props.payload[0].payload.title}</Box>);
    }
    return null;
}

export const UdemyCourseRankingGraph: FC<Props> = memo((props) => {

    const { udemyCourseRankingsData, barGraphClickListner } = props;

    let data = udemyCourseRankingsData?.slice(0, 5).map((rankingData, index) => {
        return { name: rankingData.udemyCourseImage, uv: rankingData.udemyLgtm, title: rankingData.udemyTitle };
    });

    const graphWidth = useBreakpointValue({ base: "80%", md: "60%", lg: "50%" }) || "80%";
    // const width = window.innerWidth * parseFloat(graphWidth) / 100;
    const width = Math.max(window.innerWidth * parseFloat(graphWidth) / 100, 300);

    // const height = 500 * parseFloat(graphWidth) / 100;

    return (
        <Center mb={2}>
            <BarChart
                width={width}
                height={250}
                data={data}
                margin={{
                    top: 5, right: 5, left: 5, bottom: 5,
                }}
                barCategoryGap={20}
            >
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" tick={<CustomizedAxisTick />} />
                <YAxis label={{ value: 'LGTM', angle: -90, position: 'insideLeft' }} />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="uv" fill="#8884d8" minPointSize={0} shape={<CustomizedBar />} onClick={(data, index) => barGraphClickListner(index)}>
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.uv < 10000 ? '#82ca9d' : '#8884d8'} />
                        // <Cell key={`cell-${index}`} fill={entry.uv < 10000 ? '#f6a344' : '#8884d8'} />
                    ))}
                </Bar>
            </BarChart>
        </Center>
    );
});
