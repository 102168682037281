import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";

type Props = {
    title: string;
    status: "info" | "warning" | "success" | "error";
    position?: "top" | "top-right" | "top-left" | "bottom" | "bottom-right" | "bottom-left";
    duration?: number;
};

export const useMessage = () => {
    const toast = useToast();
    const showMessage = useCallback(
        (props: Props) => {
            const { title, status, position = "top", duration = 2000 } = props;
            toast({
                title,
                status,
                position,
                duration,
                isClosable: true
            });
        },
        [toast]
    );
    return { showMessage };
};
