import { Badge, Box, Center, Stack } from "@chakra-ui/react";
import { FC, memo } from "react";
import { useHistory } from "react-router-dom";
import { badgeColorCodeIsActive } from "../shared/colorCode";

type Props = {
    tagName: string;
};

export const TagCaption: FC<Props> = memo((props) => {

    const { tagName } = props;
    const history = useHistory();

    return (
        <Center>
            <Stack direction='row' spacing={{ base: "0", md: "2" }} align='center' mt={4}>
                <Box>
                    <Badge variant='solid' mx={1} px={2} py={1} colorScheme='green'
                        bg={badgeColorCodeIsActive} fontSize={{ base: "10", md: "xs" }}
                        onClick={() => history.push('/')}
                        sx={{
                            transition: "opacity .3s ease",
                            '&:hover': {
                                opacity: 0.7,
                                cursor: 'pointer',
                            }
                        }}
                    >
                        {tagName}
                    </Badge>

                </Box>
                {/* <Text fontSize={{ base: "sm", md: "md" }} p={1}>
                    {tagName}
                </Text> */}
            </Stack>

        </Center>
    )
});