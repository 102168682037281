/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, Heading, IconButton, Link, Stack, useDisclosure } from "@chakra-ui/react";
import { FC, memo, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { MenuIconButton } from "../../atoms/MenuIconButton";
import { MenuDrawer } from "../../molecules/MenuDrawer";
import { TagSearchDrawer } from "../../molecules/TagSearchDrawer";

export const Header: FC = memo(() => {

    const menuBarModal = useDisclosure()
    const searchTagModal = useDisclosure()

    const history = useHistory();
    const location = useLocation();

    const onClickHome = useCallback(() => { history.push("/"); menuBarModal.onClose(); }, []);
    const onClickTagRanking = useCallback(
        () => { history.push("/tag-ranking"); menuBarModal.onClose(); },
        []
    );
    const onClickAbout = useCallback(() => { history.push("/about"); menuBarModal.onClose(); }, []);
    const onClickContact = useCallback(() => { history.push("/contact"); menuBarModal.onClose(); }, []);

    // 数値を千単位で丸め、文字列で表示する
    const formatNumberForDisplay = (num: number) => {
        return Math.round(num / 1000) / 10 + "万";
    }


    const getQiitaArticlesFromAnalysisStartDate = () => {
        // 現在の日付を取得
        let now = new Date();
        // Qiita記事の投稿数（1日あたり）
        const numQiitaArticlesPerDay = 260;

        // 2023年2月13日の日付を取得
        // JavaScriptのDateオブジェクトでは月は0から始まるため、2月は1となります
        let feb13 = new Date(2023, 1, 13);

        // 両日付の差分をミリ秒単位で取得し、それを日単位に変換
        // Math.abs() を用いて絶対値を取ることで、負の値が出た場合に対処
        let diffDays = Math.abs((feb13.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));

        return formatNumberForDisplay(diffDays * numQiitaArticlesPerDay)
    }


    return (
        <header>
            <Flex
                bg="green.400"
                fontWeight="bold"
                color="white"
                align="center"
                // justify="space-between"
                justify="center"
                padding={{ base: 3, md: 5 }}
            >
                <Stack>
                    <Center>
                        <Heading
                            as="h1"
                            fontSize={{ base: "md", md: "2xl" }}
                            display={{ base: "none", md: "block" }}
                        >
                            {/* Udemy講座ランキング - 全Qiita記事を集計・分析 - */}
                            ITスキル特化: Udemyのおすすめ講座ランキング
                        </Heading>
                        <Heading
                            as="h1"
                            fontSize={{ base: "md", md: "2xl" }}
                            display={{ base: "block", md: "none" }}
                        >
                            {/* <Center m={0} p={0}>Udemy講座ランキング</Center>
                            <Center m={0} p={0}>- 全Qiita記事を集計・分析 -</Center> */}
                            <Center m={0} p={0}>ITスキル特化: Udemyのおすすめ講座ランキング</Center>
                        </Heading>
                    </Center>
                    <Flex
                        align="center"
                        fontSize="sm"
                        justify="center"
                    >
                        <Center mr={5}
                            display={{ base: "none", md: "block" }}>
                            エンジニア知識共有サービスのQiita記事を分析。 おすすめUdemy講座をランキング化
                        </Center>
                        <Center mr={5}
                            display={{ base: "block", md: "none" }}>
                            エンジニア知識共有サービスのQiita記事を分析<br /><Center>おすすめUdemy講座をランキング化</Center>
                        </Center>
                    </Flex>
                    <Center>約 {getQiitaArticlesFromAnalysisStartDate()} 記事分析（対象：2023年 2/13〜）</Center>
                    <Flex
                        as="nav"
                        align="center"
                        justify="center"
                        display={{ base: "none", md: "flex" }}
                        fontWeight="semibold"
                        // paddingLeft={7}
                        paddingTop={3}
                        fontSize="sm"
                        _hover={{ cursor: "pointer" }}
                    >
                        <Box px={3} borderRight="1px solid">
                            <Link onClick={onClickHome} borderBottom={location.pathname === '/' ? "1px solid" : {}}
                                style={location.pathname === '/' ? { textDecoration: 'none' } : {}}>トップページ</Link>
                        </Box>
                        {/* <Box px={3} borderRight="1px solid">
                            <Link onClick={onClickTagRanking} borderBottom={location.pathname === '/tag-ranking' ? "1px solid" : {}}
                                style={location.pathname === '/tag-ranking' ? { textDecoration: 'none' } : {}}>タグランキング</Link >
                        </Box> */}
                        <Box px={3} borderRight="1px solid" >
                            <Link onClick={onClickAbout} borderBottom={location.pathname === '/about' ? "1px solid" : {}}
                                style={location.pathname === '/about' ? { textDecoration: 'none' } : {}}>サイト説明</Link>
                        </Box>
                        <Box px={3} borderRight="1px solid" >
                            <Link onClick={onClickContact} borderBottom={location.pathname === '/contact' ? "1px solid" : {}}
                                style={location.pathname === '/contact' ? { textDecoration: 'none' } : {}} >お問合せ</Link>
                        </Box>
                    </Flex>
                    <MenuIconButton onOpen={menuBarModal.onOpen} />
                    <IconButton
                        onClick={searchTagModal.onOpen}
                        variant='ghost'
                        size={{ base: "md", md: "lg" }}
                        aria-label='Search tags'
                        colorScheme='teal'
                        marginRight={12}
                        _hover={{ cursor: "pointer" }}
                        _focus={{ boxShadow: "none" }}
                        style={{
                            position: "fixed",
                            top: 0,
                            left: 5,
                            border: "none"
                        }}
                        icon={<SearchIcon />}
                    />
                </Stack>
            </Flex >

            <MenuDrawer
                isOpen={menuBarModal.isOpen}
                onClose={menuBarModal.onClose}
                onClickHome={onClickHome}
                onClickTagRanking={onClickTagRanking}
                onClickAbout={onClickAbout}
                onClickContact={onClickContact}
            />
            <TagSearchDrawer
                isOpen={searchTagModal.isOpen}
                onClose={searchTagModal.onClose}
            />
        </header >
    );
});
