import { Box, Center } from "@chakra-ui/react";
import { FC, memo } from "react";

import { Button, FormControl, FormLabel, Icon, Input, Link, Textarea } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FaTwitter } from 'react-icons/fa';
import axiosInstance from "../../hooks/axiosInstance";
import { useMessage } from "../../hooks/useMessage";

type FormData = {
    name: string;
    email: string;
    subject: string;
    message: string;
};


export const Contact: FC = memo(() => {
    const { register, handleSubmit } = useForm<FormData>();
    const { showMessage } = useMessage();

    const onSubmit = (data: FormData) => {
        // Get CSRF token
        axiosInstance.get('/csrf-token', {
            withCredentials: true
        })
            .then(res => {
                const csrfToken = res.data.csrfToken;
                // Set CSRF token for this request
                axiosInstance.defaults.headers.common['X-CSRFToken'] = csrfToken;

                // Submit the form
                axiosInstance.post('/api/email-submit', data, {
                    withCredentials: true
                })
                    .then(res2 => {
                        //　メール送信に成功したら、メッセージ表示後、TopPageにリダイレクトする
                        showMessage({
                            title: "お問い合わせ、ありがとうございました。\n 5秒後に、トップページに遷移します。",
                            status: "info",
                            position: 'bottom',
                            duration: 5000
                        });
                        setTimeout(() => {
                            window.location.href = "/";
                        }, 5 * 1000);
                    })
                    .catch(err2 => showMessage({
                        title: "データ取得に失敗しました。",
                        status: "error"
                    }));
            }).catch((e) => {
                showMessage({
                    title: "データ取得に失敗しました。",
                    status: "error"
                });
            })
    }


    return (
        <>
            <Center p={0} m={0}>

                <Box my="5" p="5" borderWidth={4} borderRadius="md" w={{ base: "95%", md: "60%" }}>
                    <Box my="5">お問い合わせは、下記フォームを入力した後「Submit」、もしくは、Twitterリンクよりお願い致します。</Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl id="name" isRequired >
                            <FormLabel>名前（ニックネーム可）</FormLabel>
                            <Input {...register("name")} bg="rgba(244, 244, 244, 1)" borderWidth={2} />
                        </FormControl>

                        <FormControl id="email" isRequired mt="3">
                            <FormLabel>Email</FormLabel>
                            <Input type="email" {...register("email")} bg="rgba(244, 244, 244, 1)" borderWidth={2} />
                        </FormControl>

                        <FormControl id="subject" isRequired mt="3">
                            <FormLabel>題名</FormLabel>
                            <Input {...register("subject")} bg="rgba(244, 244, 244, 1)" borderWidth={2} />
                        </FormControl>

                        <FormControl id="message" isRequired mt="3">
                            <FormLabel>メッセージ</FormLabel>
                            <Textarea {...register("message")} bg="rgba(244, 244, 244, 1)" borderWidth={2} />
                        </FormControl>

                        <Button type="submit" colorScheme="teal" mt="3">
                            Submit
                        </Button>

                        <Box mt="5">
                            <Link href="https://twitter.com/zero__cheese" isExternal>
                                <Icon as={FaTwitter} boxSize={6} color="twitter.500" />
                            </Link>
                        </Box>
                    </form>
                </Box>
            </Center>
        </>
    );
});
