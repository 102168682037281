import {
    Button,
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay
} from "@chakra-ui/react";
import { FC, memo } from "react";

type Props = {
    onClose: () => void;
    isOpen: boolean;
    onClickHome: () => void;
    onClickTagRanking: () => void;
    onClickAbout: () => void;
    onClickContact: () => void;
};

export const MenuDrawer: FC<Props> = memo((props) => {
    const {
        onClose,
        isOpen,
        onClickHome,
        onClickTagRanking,
        onClickAbout,
        onClickContact
    } = props;
    return (
        <Drawer placement="right" size="xs" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay>
                <DrawerContent>
                    <DrawerBody p={0} bg="gray.100">
                        <Button w="100%" onClick={onClickHome}>
                            トップページ
                        </Button>
                        <Button w="100%" onClick={onClickAbout}>
                            サイト説明
                        </Button>
                        <Button w="100%" onClick={onClickContact}>
                            連絡先
                        </Button>
                    </DrawerBody>
                </DrawerContent>
            </DrawerOverlay>
        </Drawer>
    );
});