import { Box, Flex, Link } from "@chakra-ui/react";
import { FC, memo, useEffect, useState } from "react";

type Props = {
    isYearTerm: boolean;
    onClickYearTermChoice: () => void;
    onClickAllTermChoice: () => void
};


export const TermChoiceMenu: FC<Props> = memo((props) => {

    const { isYearTerm, onClickYearTermChoice, onClickAllTermChoice } = props;
    const [positionZ, setPositionZ] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(60);

    const scrollTop = (): void => {
        // console.log(window.innerWidth);
        if (window.innerWidth <= 767) {
            setHeaderHeight(110);
        } else {
            setHeaderHeight(120);
        }
        setPositionZ(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
    };

    useEffect(() => {
        document.addEventListener("scroll", scrollTop);
    }, []);


    return (<>
        <Flex
            align="center"
            justify="center"
            paddingTop={3}
            fontSize="md"
            color="gray.900"
            _hover={{ cursor: "pointer" }}
            style={positionZ >= headerHeight ? { position: "fixed", top: "0", width: "100%" } : {}}
        >
            <Box px={3} borderRight="1px solid">
                <Link onClick={onClickYearTermChoice} borderBottom={isYearTerm ? "1px solid" : {}}
                    style={isYearTerm ? { textDecoration: 'none' } : {}}>1ヶ月集計</Link>
            </Box>
            <Box px={3}>
                <Link onClick={onClickAllTermChoice} borderBottom={!isYearTerm ? "1px solid" : {}}
                    style={!isYearTerm ? { textDecoration: 'none' } : {}}>全期間集計</Link >
            </Box>
        </Flex>
    </>)
});