import {
    Button,
    Drawer,
    DrawerBody, DrawerContent, DrawerFooter,
    DrawerHeader, DrawerOverlay, Input
} from "@chakra-ui/react";
import { FC, memo, useState } from "react";
import { useHistory } from "react-router-dom";

type Props = {
    onClose: () => void;
    isOpen: boolean;
};

export const TagSearchDrawer: FC<Props> = memo((props) => {
    const {
        onClose,
        isOpen
    } = props;

    const [value, setValue] = useState('');
    const history = useHistory();

    const onButtonClick = () => {
        history.push({ pathname: '/tag-ranking', state: { tagName: value, unique: Date.now(), key: Date.now().toString() } });
        onClose();
        setValue('');
    }

    return (
        <Drawer placement="left" size="xs" onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader>タグを検索</DrawerHeader>

                <DrawerBody>
                    <Input
                        placeholder='例）Python'
                        value={value}
                        onChange={(e) => setValue(e.currentTarget.value)}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                onButtonClick()
                            }
                        }}
                    />
                </DrawerBody>

                <DrawerFooter>
                    <Button
                        colorScheme='blue'
                        onClick={onButtonClick}
                    >検索</Button>
                </DrawerFooter>
            </DrawerContent>
        </Drawer>
    );
});