import { Box, Center, Image } from "@chakra-ui/react";
import { FC, memo } from "react";

import notFoundImage from "../../images/404_error_image.png";


export const NotFound: FC = memo(() => {


    return (
        <Box w='100%' p={0} m={0}>
            <Center>
                <Image src={notFoundImage} alt="404_error_image" mt={4} boxSize={{ base: "95%", md: "60%" }} />

            </Center>
        </Box>
    );
});
