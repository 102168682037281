import React, { useEffect } from "react";

interface InfiniteScrollProps {
    children: React.ReactNode;
    onReachBottom: () => void;
    isLoading: boolean;
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
    children,
    onReachBottom,
    isLoading,
}) => {
    const sentinelRef = React.useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleIntersect: IntersectionObserverCallback = (entries) => {
            const isIntersecting = entries.some((entry) => entry.isIntersecting);
            // console.log("Is intersecting: ", isIntersecting); // 追加
            if (isIntersecting && !isLoading) {
                onReachBottom();
            }
        };

        const observer = new IntersectionObserver(handleIntersect, {
            // threshold: 0.92,
            threshold: 1.0,
        });

        if (sentinelRef.current) {
            observer.observe(sentinelRef.current);
        }

        return () => {
            if (sentinelRef.current) {
                observer.unobserve(sentinelRef.current);
            }
        };
    }, [onReachBottom, isLoading]);

    return (
        <div style={{ overflowY: "scroll", height: "100%" }}>
            {children}
            <div ref={sentinelRef} style={{ height: "1px", marginBottom: "1px" }} />
        </div>
    );
};

export default InfiniteScroll;




// import React, { useEffect, useRef } from "react";

// interface InfiniteScrollProps {
//     children: React.ReactNode;
//     onReachBottom: () => void;
//     isLoading: boolean;
// }

// const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
//     children,
//     onReachBottom,
//     isLoading,
// }) => {
//     const containerRef = useRef<HTMLDivElement>(null);
//     const observerRef = useRef<IntersectionObserver | null>(null);


//     useEffect(() => {
//         const handleIntersect: IntersectionObserverCallback = (entries) => {
//             const isIntersecting = entries.some((entry) => entry.isIntersecting);
//             if (isIntersecting && !isLoading) {
//                 onReachBottom();
//             }
//         };

//         if (containerRef.current) {
//             if (observerRef.current) {
//                 observerRef.current.disconnect();
//             }

//             const sentinel = document.createElement("div");
//             sentinel.style.height = "1px";
//             containerRef.current.appendChild(sentinel);

//             observerRef.current = new IntersectionObserver(handleIntersect, {
//                 threshold: 1.0,
//             });
//             observerRef.current.observe(sentinel);

//             return () => {
//                 containerRef.current?.removeChild(sentinel);
//             };
//         }

//         return () => {
//             if (observerRef.current) {
//                 observerRef.current.disconnect();
//             }
//         };
//     }, [containerRef, onReachBottom, isLoading]);

//     return (
//         <div ref={containerRef} style={{ overflowY: "scroll", height: "100%" }}>
//             {children}
//         </div>
//     );
// };

// export default InfiniteScroll;
