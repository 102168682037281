// useGetUdemyCourseRankings.ts
import { useCallback, useEffect, useRef, useState } from "react";
import { UdemyCourseApiInfoType, UdemyCourseRankingType } from "../types/udemyCourseRankingType";
import axiosInstance from "./axiosInstance";
import { useMessage } from "./useMessage";

export const useGetUdemyCourseRankings = () => {

    const perPage = 50;

    const { showMessage } = useMessage();

    const [rankingTerm, setRankingTerm] = useState('all-term');
    const [udemyTag, setUdemyTag] = useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const [apiLoading, setApiLoading] = useState(false);

    const [udemyCourseApiInfo, setUdemyCourseApiInfo] = useState<UdemyCourseApiInfoType>();
    const [udemyCourseRankingsData, setUdemyCourseRankingData] = useState<UdemyCourseRankingType[]>([]);


    const prevRankingTerm = useRef(rankingTerm);
    const prevUdemyTag = useRef(udemyTag);


    const getUdemyCourseRankingsApi = useCallback(() => {
        setApiLoading(true);

        // Get CSRF token
        axiosInstance.get('/csrf-token', {
            withCredentials: true
        })
            .then(res => {
                const csrfToken = res.data.csrfToken;

                // Set CSRF token for this request
                axiosInstance.defaults.headers.common['X-CSRFToken'] = csrfToken;

                axiosInstance.get<UdemyCourseApiInfoType>('/api/get_udemy_course_ranking', {
                    withCredentials: true,
                    params: {
                        ranking_term: rankingTerm,
                        udemy_tag: udemyTag,
                        current_page: currentPage,
                        per_page: perPage
                    }
                }).then((res) => {
                    if (res.data['status'] === 'success') {
                        // console.log(res.data);
                        setUdemyCourseApiInfo(res.data);
                        setUdemyCourseRankingData((prevData) => [...prevData, ...res.data.udemyCourseRankings]);
                    } else {
                        showMessage({
                            title: "データ取得に失敗しました。",
                            status: "error"
                        });
                    }
                }).catch((e) => {
                    // console.log(e.message);
                    showMessage({
                        title: "データ取得に失敗しました。",
                        status: "error"
                    });
                })
                    .finally(() => {
                        setApiLoading(false);
                    });

            });

    }, [showMessage, rankingTerm, udemyTag, perPage]);



    const loadMoreFunc = () => {
        if (udemyCourseApiInfo?.status !== "term-changed") {
            // console.log('loadMoreFunc実行');
            if (!apiLoading && udemyCourseApiInfo?.hasNext === true) {
                // console.log('loadMoreFunc  current_page: ', currentPage);
                setCurrentPage((prevPage) => prevPage + 1);
            }
        }
    }

    const choiceDataTermFunc = (term: string) => {
        // 初期化
        setUdemyCourseRankingData([]);
        setCurrentPage(1);
        setRankingTerm(term);
        setUdemyCourseApiInfo((prevState) => {
            if (!prevState) {
                // ここでデフォルト値を返す
                return {
                    status: "term-changed",
                    currentPage: 1,
                    totalPages: 1,
                    totalData: 0,
                    hasPrev: false,
                    hasNext: true,
                    udemyCourseRankings: [],
                };
            }
            return {
                ...prevState,
                status: "term-changed",
                hasNext: true,
            };
        });
    };


    useEffect(() => {
        if ((currentPage > 1 && udemyCourseApiInfo?.hasNext === true) || (udemyCourseApiInfo?.status === "term-changed")) {
            // console.log('Effectうちから読み');
            // console.log('current_page: ', currentPage);

            setApiLoading(true);

            axiosInstance.get<UdemyCourseApiInfoType>('/api/get_udemy_course_ranking', {
                params: {
                    ranking_term: rankingTerm,
                    udemy_tag: udemyTag,
                    current_page: currentPage,
                    per_page: perPage
                }
            }).then((res) => {
                if (res.data['status'] === 'success') {
                    // console.log(res.data);
                    setUdemyCourseApiInfo(res.data);
                    setUdemyCourseRankingData((prevData) => [...prevData, ...res.data.udemyCourseRankings]);
                } else {
                    showMessage({
                        title: "データ取得に失敗しました。",
                        status: "error"
                    });
                }
            }).catch((e) => {
                // console.log(e.message);
                showMessage({
                    title: "データ取得に失敗しました。",
                    status: "error"
                });
            })
                .finally(() => {
                    setApiLoading(false);
                });
        }
    }, [currentPage, rankingTerm]);


    return {
        getUdemyCourseRankingsApi, loadMoreFunc, apiLoading, udemyCourseRankingsData, setRankingTerm,
        setUdemyTag, currentPage, choiceDataTermFunc, udemyTag, setUdemyCourseRankingData
    };
}