import {
    Box, Center, CircularProgress,
    CircularProgressLabel, Stack, VStack
} from "@chakra-ui/react";
import { FC, memo, useEffect, useState } from "react";
import { useGetUdemyCourseRankings } from "../../hooks/useGetUdemyCourseRankings";
import InfiniteScroll from "../../shared/InfiniteScroll";
import { AnalysisResultBox } from "../organisums/AnalysisResultBox";
import { TermChoiceMenu } from "../organisums/TermChoiceMenu";
import { UdemyCourseRankingGraph } from "../organisums/UdemyCourseRankingGraph";
import { SnsIconLayout } from "../organisums/layout/SnsIconLayout";


export const Home: FC = memo(() => {

    const [isYearTerm, setIsYearTerm] = useState(false);

    const { getUdemyCourseRankingsApi, loadMoreFunc, apiLoading, udemyCourseRankingsData, setRankingTerm,
        setUdemyTag, currentPage, choiceDataTermFunc } = useGetUdemyCourseRankings();

    const onClickYearTermChoice = () => {
        if (!isYearTerm) {
            setIsYearTerm(true);
            choiceDataTermFunc('1m');
        }
    };
    const onClickAllTermChoice = () => {
        if (isYearTerm) {
            setIsYearTerm(false);
            choiceDataTermFunc('all-term');
        }
    };

    useEffect(() => {
        getUdemyCourseRankingsApi();
    }, [])

    const barGraphClickListner = (index: number) => {
        const element = document.getElementById(`analysis-${index}`);
        if (element) element.scrollIntoView({ behavior: 'smooth' });
    };


    return (
        <Center p={0} m={0}>
            <Box w='100%' p={0} m={0}>
                <Stack>
                    <TermChoiceMenu
                        isYearTerm={isYearTerm}
                        onClickYearTermChoice={onClickYearTermChoice}
                        onClickAllTermChoice={onClickAllTermChoice} />
                    <SnsIconLayout />

                    <InfiniteScroll onReachBottom={loadMoreFunc} isLoading={apiLoading}>
                        {/* <InfiniteScroll onReachBottom={() => { }}> */}
                        {udemyCourseRankingsData.length === 0 && apiLoading ? (
                            <VStack pt={20} mt={10}>
                                <Center>
                                    <CircularProgressLabel pt={20} fontSize="lg">Loading...</CircularProgressLabel>
                                    <CircularProgress isIndeterminate color="green.300" />
                                </Center>
                            </VStack>
                        ) : (
                            <>
                                <UdemyCourseRankingGraph udemyCourseRankingsData={udemyCourseRankingsData} barGraphClickListner={barGraphClickListner} />
                                {udemyCourseRankingsData?.map((rankingData, index) => {
                                    return (
                                        <AnalysisResultBox key={index} id={`analysis-${index}`} rankingData={rankingData} />
                                    )
                                })}
                            </>
                        )}
                        {apiLoading && udemyCourseRankingsData.length > 0 && (
                            <Center>
                                <CircularProgressLabel pt={20} fontSize="lg">Loading...</CircularProgressLabel>
                                <CircularProgress isIndeterminate color="green.300" />
                            </Center>
                        )}
                    </InfiniteScroll>

                </Stack>
            </Box>
        </Center>
    );
});
