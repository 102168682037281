import { HamburgerIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { FC, memo } from "react";


type Props = {
    onOpen: () => void;
};

export const MenuIconButton: FC<Props> = memo((props) => {
    const { onOpen } = props;
    return (
        <IconButton
            style={{
                position: "fixed",
                top: 0,
                right: 0
            }}
            aria-label="メニューボタン"
            icon={<HamburgerIcon />}
            size={{ base: "md", md: "lg" }}
            color="gray.800"
            variant="unstyled"
            // display={{ base: "block", md: "none" }}
            onClick={onOpen}
        />
    );
});
